import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import * as API from './api';
import * as Contract from './contract';

export const getRecentBlocks = createAsyncThunk("block/recent", async () => {
    return await new Promise((resolve, reject) => {
        API.getRecentBlocks(function(result){
            resolve(result);
        });
    });
});

export const getBlockDetail = createAsyncThunk("block/detail", async (data) => {
    return await new Promise((resolve, reject) => {
        API.getBlockDetail(data, function(result){
            resolve(result);
        });
    });
});

export const getRecentTransactions = createAsyncThunk("transaction/recent", async () => {
    return await new Promise((resolve, reject) => {
        API.getRecentTransactions(function(result){
            resolve(result);
        });
    });
});

export const getTransactionsByFrom = createAsyncThunk("transaction/from", async (data) => {
    return await new Promise((resolve, reject) => {
        API.getTransactionsByFrom(data, function(result){
            resolve(result);
        });
    });
});

export const getTransactionDetail = createAsyncThunk("transaction/detail", async (data) => {
    return await new Promise((resolve, reject) => {
        API.getTransactionDetail(data, function(result){
            resolve(result);
        });
    });
});

export const getData = createAsyncThunk("profile/getData", async (data) => {
    return await new Promise((resolve, reject) => {
        API.getData(data, function(result){
            // console.log(data, result)
            resolve({data, result});
        });
    });
});

export const getBalanceData = createAsyncThunk("profile/balance", async (data) => {
    return await new Promise((resolve, reject) => {
        Contract.getBalanceData(data, function(res){
            resolve(res);
        });
    });
});

export const getNFTCounts = createAsyncThunk("nft/count", async (data) => {
    return await new Promise((resolve, reject) => {
        Contract.getNFTCounts(data, function(res){
            resolve(res);
        });
    });
});

export const getSignCounts = createAsyncThunk("sign/counts", async (data) => {
    return await new Promise((resolve, reject) => {
        Contract.getSignCounts(data, function(res){
            resolve(res);
        });
    });
});

export const isUserWebUrl = createAsyncThunk("net/type", async () => {
    return await new Promise((resolve, reject) => {
        API.isUserWebUrl(function(res){
            resolve(res);
        });
    });
});

const _adapter = createEntityAdapter();
const initialState = _adapter.getInitialState({
    blocks: [],
    blockDetail: { txList: [] },
    transactions: [],
    transactionsByFrom: [],
    transactionDetail: {},
    userData: {},
    balanceData: {},
    NFTCounts: {},
    signCounts: {
        total: 200,
        signed: 0
    },
    webUrl: false
});

const userSlice = createSlice({
    name: 'chain',
    initialState,
    reducers: {
        userLogin(state, action) {
            // console.log('userLogin',action);
            state.userData.basicInfo = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(isUserWebUrl.fulfilled, (state, action) => {
            let result = action.payload;
            state.webUrl = result;
        });
        builder.addCase(getRecentBlocks.fulfilled, (state, action) => {
            let blocks = action.payload;
            state.blocks = blocks;
        });
        builder.addCase(getBlockDetail.fulfilled, (state, action) => {
            let blockDetail = action.payload;
            state.blockDetail = blockDetail;
        });
        builder.addCase(getRecentTransactions.fulfilled, (state, action) => {
            let transactions = action.payload;
            state.transactions = transactions;
        });
        builder.addCase(getTransactionsByFrom.fulfilled, (state, action) => {
            let transactions = action.payload;
            state.transactionsByFrom = transactions;
        });
        builder.addCase(getTransactionDetail.fulfilled, (state, action) => {
            let transactionDetail = action.payload;
            state.transactionDetail = transactionDetail;
        });
        builder.addCase(getData.fulfilled, (state, action) => {
            let {data, result} = action.payload;
            state.userData[data.name] = result;
        });
        builder.addCase(getBalanceData.fulfilled, (state, action) => {
            let data = action.payload;
            state.balanceData = data;
        });
        builder.addCase(getNFTCounts.fulfilled, (state, action) => {
            let data = action.payload;
            state.NFTCounts[data.name] = data;
        });
        builder.addCase(getSignCounts.fulfilled, (state, action) => {
            let signCounts = action.payload;
            state.signCounts = signCounts || {};
        });
    }
});
export const { userLogin } = userSlice.actions

export default userSlice.reducer;