import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";

import * as API from '../services/api';
import { getData, postData } from '../services/slice'
import { Rect } from '../components';
import { dateFormat, shortHash, isEmailType } from '../utils/utils';
import { getAccountInfo } from '../utils/ui-res';
import * as cache from '../utils/cache';

export default function Setting(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const account = cache.get('account') || '';
    console.log('setting', account);
    if(!account){
    	navigate('/');
    }
	let accountInfo = getAccountInfo(account);

    const { settingInfo = {} } = useSelector(function(state){
        return state.chain.userData;
    });

    const [ email, setEmail ] = useState(settingInfo.email);
    const [ isEmailErr, setIsEmailErr ] = useState(isEmailType(settingInfo.email));
    const [ isSend, setIsSend ] = useState(false);

    function bindEmail(){
	    let ts = Math.floor(new Date()/1000);
        window.ethereum.request({
            method: 'personal_sign',
            params: [email + ts, account],
        }).then(function(sign){
        	let params = {
        		address: account,
				email: email,
				signature: sign,
				ts: ts
        	};
	        API.postData({
	            name: 'bindEmail',
	            params: params
	        }, function(result){
	            console.log('bindEmail', result);
	        });
        });
    }

    useEffect(()=>{
        dispatch(getData({
            name: 'settingInfo',
            params: {
                account
            }
        }));
    }, []);

    useEffect(()=>{
        setEmail(settingInfo.email);
        setIsEmailErr(isEmailType(settingInfo.email))
    }, [settingInfo.email]);

	return (
		<div className="chain-body">
			<div className="website-part-main" style={{ width: '700px', margin: '20px auto'}}>
	            <div className="chain-content-title">
	                <h2>Setting</h2>
	            </div>
				<div className="chain-profile-setting">
					<img src={ settingInfo.icon_url || accountInfo.avatar } className="avatar" />
					<div className="chain-profile-setting-item">
						<label>User Name</label>
						<input type="text" value={ settingInfo.username || accountInfo.name } readOnly />
					</div>
					{settingInfo.email ? 
					<div className="chain-profile-setting-item">
						<label>Email Address</label>
						<input type="text" 
							readOnly
							value={ settingInfo.email } 
							style={{ width: '320px', marginRight: '10px' }} />
					</div> :
					<div className="chain-profile-setting-item">
						<label>Email Address</label>
						<input type="text" 
							onKeyUp = { (e)=>{
								let v = e.target.value;
								let isEmail = isEmailType(v);
								setIsEmailErr(isEmail);
								setEmail(v);
							}}
							style={{ width: '320px', marginRight: '10px' }} />
						{isEmailErr 
							? <span className="chain-btn" onClick={ ()=>{
								bindEmail();
								setIsSend(true);
								}}>VERIFY</span>
							: <span className="chain-btn chain-btn-disabled">VERIFY</span>
						}
						{(!isEmailErr && email) && <div className="chain-setting-warning">Please enter a correct email.</div>}
						{isSend && <div className="chain-setting-tips">Please check your email for verify.</div>}
					</div>}
					<div className="chain-profile-setting-item">
						<label>Wallet Address</label>
						<input type="text" value={ account } readOnly />
					</div>
					<div style={{ textAlign: 'center', padding: '20px', display: 'none' }}>
						<span className="chain-btn">SAVE</span>
					</div>
				</div>
			</div>
		</div>
	)
}