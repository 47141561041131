import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";

import * as cache from '../utils/cache';

import Wallets from './wallets';
import Medals from './medals';
import Activities from './activities';

export default function Profile(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const account = cache.get('account') || '';
    console.log('profile', account);
    if(!account){
    	navigate('/');
    }

    useEffect(()=>{
    }, []);

	return (
		<div className="chain-body chain-body-map">
			<div className="website-part-main">
				<Wallets account={ account } />
				<div style={{ height: '40px' }} />
				
				<Medals account={ account } />
				<div style={{ height: '40px' }} />

				<Activities account={ account } />
				<div style={{ height: '40px' }} />
			</div>
		</div>
	)
}