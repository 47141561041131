import { redirect, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//detect, login, transChain
import { userLogin, getData, postData } from "../services/slice";
import * as EthFuns from "../services/ethereum";
import { dateFormat, shortHash } from '../utils/utils';
import { imageData, getAccountInfo } from '../utils/ui-res';
import * as cache from '../utils/cache';
import { basicInfo } from '../const';

const images = imageData();

export default function Menu(props){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { settingInfo = {}, accountInfo = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    // console.log(settingInfo, accountInfo)

    const [ account, setAccount ] = useState(accountInfo.account);
    const userInfo = getAccountInfo(account);

    const [ isOasis, setIsOasis ] = useState(true);
    EthFuns.detect(function(result){
        setIsOasis(result);
    });

    function syncAccount(account){
        cache.set('account', account, true);
        setAccount(account);
        dispatch(userLogin({
            account: account
        }));
        dispatch(getData({
            name: 'accountInfo',
            params: {
                account
            }
        }));
        dispatch(getData({
            name: 'settingInfo',
            params: {
                account
            }
        }));
    }
    function loginToMetaMask(){
        if(isOasis){
            EthFuns.login(function(account){
                syncAccount(account);
            });
            return;
        }
        EthFuns.transChain(function(){
            EthFuns.login(function(account){
                syncAccount(account);
            });
        });
    }

    const [ showSubMenu, setShowSubMenu ] = useState(false);

    useEffect(()=>{
        const address = cache.get('account');
        setAccount(address);

        // loginToMetaMask();
        document.body.onclick = function(e){
            let menu = document.querySelector('.website-user-menu');
            let btn = document.querySelector('.userinfo');
            if(!menu || !btn){
                return;
            }
            let target = e.srcElement;
            let isInBtn = target.compareDocumentPosition(btn) !== 0 && target.compareDocumentPosition(btn) !== 10;
            let isInMenu = target.compareDocumentPosition(menu) !== 0 && target.compareDocumentPosition(menu) !== 10;
            if(isInBtn && isInMenu){
                setShowSubMenu(false);
            }
        }
    }, []);

    useEffect(()=>{
        setShowSubMenu(false);
    }, [window.location.href]);

    const pathname = window.location.pathname;

	return (
        <div className="website-menu">
            <div className="website-part-main">
                <a href="/"><img className="logo" src={ images.logoSmall } /></a>
                <a className={ "item" + (pathname === '/' ? ' current' : '') } href="/">HOME</a>
                <a className={ "item" + (pathname === '/game' ? ' current' : '') } href={ basicInfo.game } target="_blank">GAME</a>
                <a className={ "item" + (pathname === '/event' ? ' current' : '') } href="/event">EVENT</a>

                <a style={{ display: 'none' }} className={ "item" + (pathname === '/invite' ? ' current' : '') } href="/invite">INVITATION</a>
                <a className="item" href={ basicInfo.guidebook } target="_blank">GUIDEBOOK</a>
            </div>
            <div className="website-user" style={{ display: 'none' }}>
                {account ? (<div 
                    className={showSubMenu ? "userinfo userinfo-open" : "userinfo"} 
                    onClick={ ()=>{
                        setShowSubMenu(!showSubMenu);
                        // navigate('/setting/' + account);
                    }}>
                    <img src={ settingInfo.icon_url || userInfo.avatar } className="avatar" />
                    <span className="name">{ settingInfo.username || userInfo.name }</span>
                    <span className="account">{ shortHash(account,5) }</span>
                </div>) : (<span className="chain-btn unlogin"
                    onClick={ ()=>{
                        loginToMetaMask();
                    } }>
                    Login
                </span>)}

                <div className="website-user-menu" style={{
                    display: showSubMenu ? 'block' : 'none'
                    }}>
                    <a className="user-item" href={ '/profile' }>Profile</a>
                    <a className="user-item" href={ '/setting' }>Setting</a>
                    <a className="user-item" onClick={ ()=>{
                        EthFuns.logout(function(result){
                            navigate('/');
                            setShowSubMenu(false);
                            setAccount('');
                            cache.remove('account');
                        });
                    }}>Logout</a>
                </div>
            </div>
        </div>
	);
}