const { ethereum, location } = window;
const oasisId = '0xa516';
const roseConfig = {
    chainId: oasisId, // A 0x-prefixed hexadecimal string
    chainName: 'Emerald Mainnet',
    nativeCurrency: {
        name: 'Emerald Mainnet',
        symbol: 'ROSE', // 2-6 characters long
        decimals: 18
    },
    rpcUrls: ['https://emerald.oasis.dev']
};

function addRose(callback){
    ethereum.request({ 
        method: "wallet_addEthereumChain",
        params: [roseConfig]
    }).then((result) => {
        callback(result);
    }).catch((error) => {
       console.log('addRose', error);
   });
}

function detect(callback){
    if (typeof ethereum === "undefined") {
        return;
    }
    ethereum.request({ 
        method: 'eth_chainId' 
    }).then((chainId)=>{
        callback(oasisId === chainId);      
    });
    ethereum.on('chainChanged', (accounts) => {
        // location.reload(true);
    });
    ethereum.on('accountsChanged', (accounts) => {
        // location.reload(true);
    });
}

function login(callback){
    if (typeof ethereum !== "undefined") {
        ethereum.request({ 
            method: "eth_requestAccounts" 
        }).then((accounts) => {
            // console.log(accounts);
            const account = accounts[0];
            callback(account);
        }).catch((error) => {
           console.log(error, error.code);
       });
   } else {
       window.open("https://metamask.io/download/", "_blank");
   }
}


function logout(callback){
    console.log(ethereum)
    let result = ethereum.isConnected();
    callback && callback(result);
}

function transChain(callback){
    ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: oasisId}]
    }).then((res)=>{
        callback && callback();
        // location.reload(true);
    }).catch((error)=>{
        addRose(function(result){
            console.log(result);
            callback && callback();
        });
    });
}

export {
    detect,
    login,
    logout,
    transChain
};