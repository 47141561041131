import React, { useEffect, useState }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter, HashRouter,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";

import './components/component.scss'
import './event/chain.scss';
import './profile/profile.scss';

import { Footer, Menu } from './components';

import Homepage from './home/homepage';
import EventList from './event/event';
import BlockDetail from './event/block-detail';
import WalletTransactions from './event/transactions-wallet';
import TransactionsDetail from './event/transaction';
import Profile from './profile/profile';
import Setting from './setting/setting';
import Verified from './setting/verified';
import Seasons from './game/seasons';
import InviteFriends from './invite/invite';

export default function App() {
    const dispatch = useDispatch(); 

    let isVerify = window.location.href.indexOf('/verified') > -1;
    let isHomeage = window.location.pathname === '/';

    return (<>
        <BrowserRouter>
            {!isVerify && <Menu />}
            <Routes>
                <Route exact path="/" element={ <Homepage /> } />
                <Route exact path="/event/" element={ <EventList /> } />
                <Route exact path="/block/:height" element={ <BlockDetail /> } />
                <Route exact path="/from/:wallet/transactions" element={ <WalletTransactions /> } />
                <Route exact path="/transaction/:hash" element={ <TransactionsDetail /> } />
                
                <Route exact path="/profileDrop" element={ <Profile /> } />
                <Route exact path="/settingDrop" element={ <Setting /> } />
                <Route path="/verifiedDrop" element={ <Verified /> } />
                <Route path="/gameDrop" element={ <Seasons /> } />
                <Route path="/inviteDrop" element={ <InviteFriends /> } />
            </Routes>
        </BrowserRouter>

        {!isVerify && <div className={isHomeage ? '' : 'chain-body-footer'}>
            <Footer />
        </div>}
    </>);
}