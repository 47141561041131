import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";

import { Rect } from '../components';
import { dateFormat, shortHash } from '../utils/utils';
import { basicInfo } from '../const';
import { searchStringToObject } from 'serialize-query-params';

export default function Verified(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

	const data = useParams();
	let { account } = data;

	///verified?result=failed&msg=HandleBindEmail%20token%20expired
	let qs = window.location.search;
	const params = searchStringToObject(qs);

    const [ isEmailErr, setIsEmailErr ] = useState(true);

    useEffect(()=>{
    }, []);

	return (
		<div className="chain-body">
			<div className="website-part-main" style={{ width: '700px', margin: '100px auto'}}>	
				<div className="chain-profile-verified-logo"></div>            
				{(params.result === 'failed') ? <div className="chain-profile-setting">
					<div className="chain-profile-verified">
		                <h2>Email verified failed.</h2>
						<p>{ params.msg }</p>
					</div>
				</div> : <div className="chain-profile-setting">
					<div className="chain-profile-verified">
		                <h2>Email verified.</h2>
						<p>Make it official! Tap the button below to verify your email and complete registration.</p>
					</div>
					<div style={{ textAlign: 'center', padding: '15px 30px', zoom: 1 }}>
						<a className="chain-btn" href={ basicInfo.game }>Play Game</a>
					</div>
				</div>}
			</div>
		</div>
	)
}