import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";
import { 
	getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionDetail
} from '../services/slice'

import { Footer, Menu } from '../components';
import { Rect } from '../components';
import { dateFormat } from '../utils/utils';

export default function Blocks(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { blocks } = useSelector(function(state){
        return state.chain;
    });
    // console.log('blocks', blocks);

    useEffect(()=>{
        dispatch(getRecentBlocks());
        dispatch(getRecentTransactions());
    }, []);

	return (
		<div className="website-part-main">
			<Rect content={ <>
				<table className="chain-table">
					<thead><tr>
						<td>Block ID</td>
						<td className="center">Height</td>
						<td className="center">Timestamp</td>
						<td className="center">Tx Size</td>
					</tr></thead>
					<tbody>
		                {blocks.map((item, index) =>
		                    <tr key={ index } 
		                    	onClick={ ()=>{
									let height = item.Height;
									navigate('/block/' + height);
								} }>
								<td>{ item.Id }</td>
								<td className="center">{ item.Height }</td>
								<td className="center">{ dateFormat(item.Timestamp*1000) }</td>
								<td className="center">{ item.TxSize }</td>
							</tr>
		                )}
					</tbody>
				</table>
			</> } />
		</div>
	)
}