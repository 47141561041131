import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { numberFormat, dateFormat, timeFormat, createRadarSvg } from '../utils/utils'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath
} from '../utils/ui-res'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function RankTable(props){
    const { data = [] } = props;

    return (<table>
        <thead><tr>
            <td className="center rank">RANK</td>
            <td className="player">PLAYER</td>
            <td className="center">UNION</td>
            <td className="right reward">REWARD</td>
        </tr></thead>
        <tbody>
        {data.map((item, index) => (
            <tr key={ 'rank' + index }>
                <td className="center"><span className="index">{ index + 1 }</span></td>
                <td><span style={{ display:'inline-block', lineHeight: 1, paddingTop:'7px', width:'8.2em',overflow:'hidden',textOverflow:'ellipsis' }} title={ item.username }>{ item.username }</span></td>
                <td className="center" style={{ paddingTop: '7px' }}> <img style={{ width: '20px' }} src={ unionImagePath(item.unionId || 1) } /> </td>
                <td className="right reward"> 
                    <span className="chain-icon-reward"></span>
                    x { numberFormat(item.count) } 
                </td>
            </tr>)
        )}
        </tbody>
    </table>)
}