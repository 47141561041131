import React, { useEffect, useState } from 'react';

export default function Rect(props){

    return (
        <div className="chain-rect">
            <div className="chain-rect-left">
                <div className="chain-rect-left-t"></div>
                <div className="chain-rect-left-m"></div>
                <div className="chain-rect-left-b"></div>
            </div>
            <div className="chain-rect-content">
                <div className="chain-rect-content-t"></div>
                <div className="chain-rect-content-m">
                    { props.content }
                </div>
                <div className="chain-rect-content-b"></div>
            </div>
            <div className="chain-rect-right">
                <div className="chain-rect-right-t"></div>
                <div className="chain-rect-right-m"></div>
                <div className="chain-rect-right-b"></div>
            </div>
        </div>
    );
}