let rootDir = process.env.PUBLIC_URL;
// console.log(process.env, rootDir, 'process.env')

const imageData = () => {
    return {
        logoBig: rootDir + '/assets/logo.png',    
        logoSmall: rootDir + '/assets/logo-s.png',
        ape: rootDir + '/assets/ape.png',
        airose: rootDir + '/assets/airose.png',
        nft: rootDir + '/general/19.png',
        bg1: rootDir + '/assets/bg1.jpg',
        compose: rootDir + '/assets/compose.png',
        gnr_tex_greg: rootDir + '/assets/gnr_tex_greg.png',
        gnr_tex_barret: rootDir + '/assets/gnr_tex_barret.png',
        old_man: rootDir + '/assets/old-man.png',
        battle: rootDir + '/assets/battle.png'
    };
}

const cityImagePath = (city) => {
    let type = city.type.toLowerCase();
    return rootDir + '/city/' + type + '.png';
}

const generalImagePath = (general) => {
    let index = general.index || general.generalId;
    return rootDir + '/general/gnr_' + index + '.png';
}
const generalBigImagePath = (general) => {
    let index = general.index;
    return rootDir + '/general/' + index + '.png';
}

const getAccountInfo  = (account) => {
    let index = Math.round(Math.log10(account)*99999)%24 + 1;
    return {
        avatar: generalImagePath({index}),
        name: 'Player' + index
    }
}
const generalTypeImagePath = (type) => {
    return rootDir + '/general/t' + type + '.png';
}
const generalSkillImagePath = (type) => {
    return rootDir + '/general/' + type + '.png';
}
const unionImagePath = (id) => {
    return rootDir + '/union/' + id + '.png';
} 
const actImagePath = (id) => {
    return {
        banner: rootDir + '/acts/' + id + '.png',
        icon: rootDir + '/acts/i' + id + '.png'
    };
} 
const colors = {
    0: '0x000000',
    1: '0x39cd0c', 
    2: '0x23afff', //northwest
    3: '0xffa200',
    4: '0xff2424' //
};
const getPolicyIcon = (name) => {
    return rootDir + '/strategy/' + name + '.png';
}
const getMedal = (name) => {
    return rootDir + '/medal/' + name + '.png';
}

export {
    imageData,
    cityImagePath,
    getAccountInfo,
    generalImagePath,
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath,
    colors,
    getPolicyIcon,
    actImagePath,
    getMedal
}