import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate, useParams } from "react-router-dom";
import { 
	getRecentBlocks,
    getBlockDetail,
    getTransactionsByFrom,
    getTransactionDetail
} from '../services/slice'

import { Rect } from '../components';
import { dateFormat, shortHash } from '../utils/utils';
import { txTypes } from '../const';

export default function WalletTransactions(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

	const data = useParams();
	let { wallet } = data;

    const { blockDetail, transactionsByFrom } = useSelector(function(state){
        return state.chain;
    });
    let txList = blockDetail.Txs || [];
    console.log(blockDetail, transactionsByFrom);

    useEffect(()=>{
        dispatch(getTransactionsByFrom({
        	from: wallet
        }));
    }, []);

	return (
		<div className="chain-body">
			<div className="website-part-main">

				<div className="chain-content">
					<div className="chain-content-title">
						<h2>Transctions From { wallet }</h2>
					</div>

					<Rect content={ <table className="chain-table">
						<thead><tr>
							<td>Hash</td>
							<td className="center">Transaction Index</td>
							<td className="center">Status</td>
							<td className="center">Season ID</td>
							<td className="center">Type</td>
							<td className="center">Version</td>
							<td className="center">Timestamp</td>
						</tr></thead>
						<tbody>
			                {transactionsByFrom.map((item, index) =>
			                    <tr key={ index } 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item.Hash);
									} }>
									<td title={ item.Hash }>{ shortHash(item.Hash, 7) }</td>
									<td className="center">{ item.TransactionIndex }</td>
									<td className="center chain-grids-boolean">
			                            {item.Status 
			                                ? <span className="success">Success</span>
			                                : <span className="fail">Defeat</span>
			                            }
									</td>
									<td className="center">{ item.SeasonId }</td>
									<td className="center">{ txTypes.getTypeName(item.Type) }</td>
									<td className="center">{ item.Version }</td>
									<td className="center">{ dateFormat(item.Timestamp*1000) }</td>
								</tr>
			                )}
						</tbody>
					</table> } />
				</div>
			</div>
		</div>
	)
}