import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
const { jsondiffpatch } = window;

export default function Gird2Cols(props){
    let { data, type } = props;
    
    const [copied, setCopied] = useState({});
    const [closeMark, setCloseMark] = useState({});
    const [open, setOpen] = useState(true);

    function getCls(item){
        let cls = 'chain-grids-item';
        if(item.type === 'swicth' ){
            cls += ' chain-grids-swicth';
        }
        if(closeMark[item.value]){
            cls += ' chain-grids-close';
        }
        return cls;
    }

    return (
        <div className={ "chain-grids-cols" + ( (type === 'events' && !open) ? ' chain-grids-cols-close':'')}>
            {data.map((item, index) =>
                <div 
                    className={ getCls(item) } 
                    key={ index } 
                    onClick={ (e)=>{
                        if(item.name === 'Index' && item.type === 'swicth'){
                            let result = !open;
                            setOpen(result);
                        }
                    }}>
                    <label className="name">{ item.name }</label>
                    { item.type === 'swicth' && (<>
                        <a className="chain-swicth">swicther</a>
                        <span className="value">{ item.value }</span>
                    </>)}
                    { 
                        item.type === 'diff' 
                        && (<div className="code">
                            {copied[item.name] && (<span className="copied">Copied</span>)}
                            <a className="chain-copy" onClick={ ()=>{
                                copy(JSON.stringify(item.value, null, '\t'), {
                                    onCopy: function(){
                                        setCopied({ [item.name] : true });
                                        setTimeout(function(){
                                            setCopied({ [item.name] : false });   
                                        }, 2000);
                                    }
                                });
                            }}>copy</a>
                            {
                                JSON.stringify(item.value1) !== JSON.stringify(item.value2) ?
                                <div className="value">{ "{" }
                                    <div style={{ paddingLeft: '2rem' }} dangerouslySetInnerHTML={{ 
                                        __html: jsondiffpatch.formatters.html.format(jsondiffpatch.diff(item.value2, item.value1), item.value2)
                                    }}/>
                                { "}" }</div> : 
                                <pre className="value">{ JSON.stringify(item.value1, null, '\t') }</pre>
                            }
                        </div>)
                    }
                    { 
                        item.type === 'object' 
                        && (<div className="code">
                            {copied[item.name] && (<span className="copied">Copied</span>)}
                            <a className="chain-copy" onClick={ ()=>{
                                copy(JSON.stringify(item.value, null, '\t'), {
                                    onCopy: function(){
                                        setCopied({ [item.name] : true });
                                        setTimeout(function(){
                                            setCopied({ [item.name] : false });   
                                        }, 2000);
                                    }
                                });
                            }}>copy</a>
                            <pre className="value">{ JSON.stringify(item.value, null, '\t') }</pre>
                        </div>)
                    }
                    { 
                        item.type === 'boolean' 
                        && (<div className="chain-grids-boolean">
                            {item.value 
                                ? <span className="chain-icon-success">Success</span>
                                : <span className="chain-icon-fail">Defeat</span>
                            }
                        </div>)
                    }
                    { 
                        !item.type && <span className="value">{ item.value }</span>
                    }
                </div>
            )}
        </div>
    );
}