import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate, useParams } from "react-router-dom";

import copy from 'copy-to-clipboard';

import { 
	getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionDetail
} from '../services/slice'

import { Rect, Gird2Cols } from '../components';
import { dateFormat, shortHash, getTypeName } from '../utils/utils';

import Blocks from './blocks';
import Transactions from './block';
import Dashboard from './dashboard';

export default function EventList(props){
    const dispatch = useDispatch(); 
	const data = useParams();

	const [tabCurrent, setTabCurrent] = useState('block');

    useEffect(()=>{
    }, []);

	return (
		<div className="chain-body">
			<Dashboard />

			<div style={{ height: '20px' }}></div>

			<div className="website-part-main">
				<div className="chain-content">
					<div className="chain-tabs" style={{ textAlign: 'left' }}>
						<div 
							onClick={ ()=>{
								setTabCurrent('block');
							}}
							className={tabCurrent === 'block' ? "chain-tab-highlight": "chain-tab-normal"}>
							Latest Blocks
						</div>
						<div 
							onClick={ ()=>{
								setTabCurrent('transaction');
							}}
							className={tabCurrent === 'transaction' ? "chain-tab-highlight": "chain-tab-normal"}>
							Latest Transactions
						</div>
					</div>

					{tabCurrent === 'block' && <Blocks />}
					{tabCurrent === 'transaction' && <Transactions />}
				</div>
			</div>
		</div>
	)
}