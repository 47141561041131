import BasicInfo from './basic-info';
import txTypes from './transaction-types';

const basicInfo = BasicInfo();

const medias = {
    twitter: "https://twitter.com/LOT_HQ",
    discord: "https://discord.gg/ea39AjBghJ", 
    telegram: "https://t.me/leagueofthrones"
};

export {
	basicInfo,
	medias,
	txTypes
}