import axios from 'axios';
import { subs } from '../utils/utils';
import { basicInfo } from '../const';

const apiHost = basicInfo.apiHost;

let APIs = {
    blocks: '/web/chain/blocks',
    block: '/web/chain/block/{height}',
    transactions: '/web/chain/transactions',
    transactionsByType: '/web/chain/transactions/type/{type}',
    userTransactions: '/web/chain/transactions?from={from}',
    transaction: '/web/chain/transaction/{hash}',
    stateChange: '/web/chain/transactions/laststate?seasonId={seasonId}&id={transId}&stateId={stateId}',

    bindEmail: '/web/account/settings/email/send_bind_link',
    settingInfo: '/web/account/settings?address={account}',
    accountInfo: '/web/account/info?address={account}',

    items: '/web/account/game_items?address={account}',
    medals: '/web/account/medals?address={account}',
    activities: '/web/account/season_histories?address={account}',
    seasonNew: '/web/season/actives',
    seasonHis: '/web/season/histories',

    statTotals: '/web/chain/statics?r={r}',
    statDaily: '/web/chain/transaction/statics?fromTs={startTime}&endTs={endTime}&r={r}'
}

const getData = (data, callback) => {
    let { name, params } = data;
    let api = apiHost + subs(APIs[name], params);
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    });
}

const postData = (data, callback) => {
    let { name, params } = data;
    let api = apiHost + subs(APIs[name], params);
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    axios.post(api, params).then(function(res){
        res = res.data;
        callback(res);
    });
}

const getRecentBlocks = (callback) => {
    let api = apiHost + '/web/chain/blocks';
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    });
}

const getRecentTransactions = (callback) => {
    let api = apiHost + '/web/chain/transactions';
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    });
}

const getTransactionsByFrom = (data, callback) => {
    let api = apiHost + '/web/chain/transactions?from=' + data.from;
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    });
}

const getBlockDetail = (data, callback) => {
    let api = apiHost + '/web/chain/block/' + data.height;
    axios.get(api).then(function(res){
        res = res.data;

        // let txList = [];
        // let txs = res.Txs;
        // txs.forEach(function(tx){
        //     res.tx = tx;
        //     txList.push(res);
        // });
        // console.log(txList, res);
        callback(res);
    });
}

const getTransactionDetail = (data, callback) => {
    // console.log('getTransactionDetail', data);
    let api = apiHost + '/web/chain/transaction/' + data.hash;
    axios.get(api).then(function(res){
        res = res.data;
        callback(res);
    });
}

const isUserWebUrl = (callback) => {
    let url = 'https://arweave.net/_peZoSvUWF3LFQioOh_u_uHxUParHgQuEvXMcj22iAo';
    axios.get(url, { timeout: 1000 }).then(function(res){
        callback(true);
    }).catch(function(res){
        console.log(res);
        callback(false);
    });
}

export {
    getData,
    postData,
    getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionsByFrom,
    getTransactionDetail,
    isUserWebUrl
};