export default function() {
    const guidebook = 'https://docs.leagueofthrones.com';
    
    const test = {
        game: 'http://test.leagueofthrones.com',
        web: 'http://test-web.leagueofthrones.com',
        apiHost: '//test.leagueofthrones.com',
        guidebook: guidebook
    };

    const prod = {
        game: 'https://app.leagueofthrones.com',
        web: 'https://leagueofthrones.com',
        web2: 'https://www.leagueofthrones.com',
        apiHost: '//app.leagueofthrones.com',
        guidebook: guidebook
    };

    if(window.location.href.indexOf(prod.web) !== 0 && window.location.href.indexOf(prod.web2) !== 0){
        return test;
    }
    return prod;
}