import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";
import { getData } from '../services/slice'

import { Rect } from '../components';
import { dateFormat } from '../utils/utils';
import { unionImagePath } from '../utils/ui-res';
import acti from  './assets/act.png';
import './activities.scss';

export default function Activities(props){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { activities = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    let list = activities.list || [];
    console.log(activities);

    let { account } = props;
    useEffect(()=>{
        dispatch(getData({
            name: 'activities',
            params: {
                account
            }
        }));
    }, []);

	return (
        <div className="website-part-main website-specs-title">
            <h2 className="title1">ACTIVITIES</h2>
            <div className="chain-activities">
                {list.map(
                    (item, index) =>
                        <div className="chain-activity-item-wrapper" key={ index }>
                            <div className="chain-activity-item">
                                <img className="banner" src={ acti } onClick={ ()=>{
                                    navigate('/seasons/')
                                }} />
                                <span className="rank">Rank { item.glory }</span>
                                <span className="season">{ item.seasonId }</span>
                                <span className="period">{ dateFormat(item.startTime/0.001, 'yyyy/MM/dd') } - { dateFormat(item.endTime/0.001, 'yyyy/MM/dd') }</span>

                                <div className="chain-activity-item-more">
                                    <div className="chain-split"></div>
                                    <div className="chain-activity-item-title">Victory Camp:</div>
                                    <div className="camp">
                                        {(item.winUnionid === item.unionid) 
                                        ? <span className="camp-our"><img src={ unionImagePath(item.unionid) } />(Our Side)</span>
                                        : <>
                                            <img src={ unionImagePath(item.winUnionid) } />
                                            <span className="camp-our" style={{ marginLeft: '80px'}}>
                                                <img src={ unionImagePath(item.unionid) } />(Our Side)
                                            </span>
                                        </>}
                                    </div>

                                    {item.rewards && <><div className="chain-activity-item-title">Conqueror Award:</div>
                                    <div className="award">{ item.rewards[0] }</div>

                                    <div className="chain-activity-item-title">Medal Ranking Rewards:</div>
                                    <div className="award">{ item.rewards[1] || 0 }</div>
                                    </>}
                                </div>
                            </div>
                        </div>
                )}
                {activities.length === 0 && <>
                    <div className="chain-nodata">You haven't played in any season.</div>
                </>}
            </div>
        </div>		
	)
}