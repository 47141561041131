import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generalSkillImagePath } from '../utils/ui-res';
import { isUserWebUrl } from '../services/slice';

import { imageData } from '../utils/ui-res';
const images = imageData();

const { isMobile } = window;

export default function Footer(){
    const dispatch = useDispatch(); 

    let nfts = [
        {"index":1, "name": "gnr_tex_abner", "url": "https://arweave.net/_peZoSvUWF3LFQioOh_u_uHxUParHgQuEvXMcj22iAo"},
        {"index":2, "name": "gnr_tex_addison", "url": "https://arweave.net/Ne7A1fbCQBM8T-lVm5Q34m8r0a6gBCwiWQ_ZI9prsZw"},
        {"index":3, "name": "gnr_tex_alisdair", "url": "https://arweave.net/f-GrJ8OGKOdJ8OqCauD8VdcPIr1XjKBLNb08DNJwc8k"},
        {"index":4, "name": "gnr_tex_audrey", "url": "https://arweave.net/0XmF3OuF23eLjdW7aGfwnSahl8_YM3uExOxT7bDr2Zw"},
        {"index":5, "name": "gnr_tex_barret", "url": "https://arweave.net/NSUvkjZSUupxhF7ZfaIhxDecIMqMCHFVSPWl5Ddoijs"},
        {"index":6, "name": "gnr_tex_boris", "url": "https://arweave.net/7IgZ8Llrpg6QltIE0VFErbPhBadKqg4cJFdob72Ag_w"},
        {"index":7, "name": "gnr_tex_boron", "url": "https://arweave.net/isrgGYnHcgrvtFrjbh5qvPuXflWTJ4BuR814Nu9NJmQ"},
        {"index":8, "name": "gnr_tex_breny", "url": "https://arweave.net/IJeXw1Hxtn1SA2dNAD3lbeVWl-qb_hOUo15SCqhevgM"},
        {"index":9, "name": "gnr_tex_fanny", "url": "https://arweave.net/25PXpXjmvaUJHGJPaoNz59hi6J_4fILhQ2RKuCkFA80"},
        {"index":10, "name": "gnr_tex_gemma", "url": "https://arweave.net/9jc8hg2AdC_nZBgNrWSBtVmcOmlBf1PP-8FnKVT8SG8"},
        {"index":11, "name": "gnr_tex_girald", "url": "https://arweave.net/D0IYLkiTMPN6_gcnOSiJUoGUDqVCUp0JcRVUGzSUuGM"},
        {"index":12, "name": "gnr_tex_glenn", "url": "https://arweave.net/qoYrAZwHZu1oIPPwbIS5wTsQCR6lzSFCNM24n7_caOg"},
        {"index":13, "name": "gnr_tex_greg", "url": "https://arweave.net/uKjF6RUpZS5NOZrPNga7mCtiu-NAnVLSQBkj4X9oVDk"},
        {"index":14, "name": "gnr_tex_griff", "url": "https://arweave.net/ga_WmrN1b7F_KxO8Gn6D3MK2mbn_m7x6dnBVbwnGcY4"},
        {"index":15, "name": "gnr_tex_jamie", "url": "https://arweave.net/JjkPyqOqlRcPvweFncAi3UQs4Bn9wucItuw_XGD9Tdo"},
        {"index":16, "name": "gnr_tex_jarrett", "url": "https://arweave.net/WDXk02JV5LILqkc87rGffiSQjBQX1QrT_M43Zlej4Bk"},
        {"index":17, "name": "gnr_tex_kivon", "url": "https://arweave.net/VYlgTzoDUA7ma8sjqhC0HCy-aWMbXyS03gukxWotvy0"},
        {"index":18, "name": "gnr_tex_lillian", "url": "https://arweave.net/GqqCEc64P_o7HEgUlAKafDpTi1adn3QULlLyBv86b5Y"},
        {"index":19, "name": "gnr_tex_sagard", "url": "https://arweave.net/SGF988gWbAck2Jof9UJtHBzo2kUiiVT360AErMl-EsA"},
        {"index":20, "name": "gnr_tex_sarka", "url": "https://arweave.net/Z9gdppiKK7o9Zzb6XoYYQ_HDTyYd-G9jteGvzZ7cn5o"},
        {"index":21, "name": "gnr_tex_tamina", "url": "https://arweave.net/g0pihPHw-xU3smip1zs8LsmYq_V_odYSeYi6fxpQVqY"},
        {"index":22, "name": "gnr_tex_tirula", "url": "https://arweave.net/u-_d8zd9XrJfL4cKeMsrynFllccOVF7b2QrIQgXj84w"},
        {"index":23, "name": "gnr_tex_valysca", "url": "https://arweave.net/g3T0Fm9MKOUrvCO55V9RAdF1gsMrTTGwGZTCjCKzvzU"},
        {"index":24, "name": "gnr_tex_zachary", "url": "https://arweave.net/MGQnwqvFppooriKCbIBLCYK9zD9cgVP2ysf_kaSxxIQ"}
    ];

    const { webUrl } = useSelector(function(state){
        return state.chain;
    });
    let size = 4;
    let count = nfts.length;
    let total = Math.ceil(count/size);
    const [page, setPage] = useState(0);

    useEffect(()=>{
        dispatch(isUserWebUrl());
        // let t = setInterval(function(){
        //     setPage((page + 1)%total)
        // }, 2000);
    }, [page]);

	return (
		<div className="website-nft website-part">
            <div className="website-part-main website-nft-title">
                <h2>NFT</h2>
                <p>There will be two sets of native NFTs in League of Thrones:Generals and Land Parcels.</p>
            </div>
            <div className="website-part-main website-nft-intros">
                <article>General NFTs are designed based on loot, like poker cards,the front side will be the 8 attributes from loot, the back side will be one of the generals from League of Thrones. The nine attributes will together decide the NFTs’performance within the game.</article>
                <article>Land Parcels NFTs: Players can only purchase the parcel if they conquer it during a season, like monopoly, others who stops at that parcel needs to pay tax to the owner. </article>
            </div>

            <div className="website-part-main website-gallery">
                <h2>GALLERY</h2>
                {isMobile ? (<div className="website-gallery-slides">
                    {
                        nfts.map((item, index)=>{
                            return <div className="website-gallery-slide" key={ index }><img src={ item.url } key={ index } /></div>
                        })
                    }
                </div>) : (<>
                    <div className="website-gallery-slides">
                        {
                            [{},{},{},{}].map((item, index)=>{
                                return <div className="website-gallery-slide" key={ index }>
                                    <img src={ webUrl ? nfts[page*size + index].url : generalSkillImagePath(page*size + index + 1) } />
                                </div>
                            })
                        }
                    </div>
                    <span className="website-gallery-prev" onClick={ ()=>{
                        let current = page - 1;
                        if(current < 0){
                            current = total - 1;
                        }
                        setPage(current);
                    } }>prev</span>
                    <span className="website-gallery-next" onClick={ ()=>{
                        let current = page + 1;
                        if(current >= total){
                            current = 0;
                        }
                        setPage(current);
                    } }>next</span>
                    <div className="website-gallery-index">
                        {[...Array(total)].map((x, index) =>
                            <span 
                                onClick={ ()=>{ setPage(index); } }
                                className={ index === page ? 'current' : '' } 
                                key={ index }>
                                { index }
                            </span>
                        )}
                    </div>
                </>)}
            </div>
            <img src={ images.old_man } alt="" className="old-man" />
            <img src={ images.battle } alt="" className="battle" />
        </div>
	);
}