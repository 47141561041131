import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate, useParams } from "react-router-dom";
import { NumericFormat } from 'react-number-format';

import { getData, getSignCounts } from '../services/slice'

import { Rect } from '../components';
import { dateFormat } from '../utils/utils';
import { basicInfo } from '../const';
import * as cache from '../utils/cache';

import Ranks from './ranks';
import './seasons.scss';

function isSeasonRunning(seasonInfo){
    let t1 = seasonInfo.start_ts/0.001;
    let t2 = seasonInfo.end_ts/0.001;
    let now = new Date().getTime();
    return t1 <= now && t2 >= now;
}

export default function SeasonInfo(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { seasonNew = {}, seasonHis = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    const { signCounts } = useSelector(function(state){
        return state.chain;
    });

    // console.log(signCounts, {seasonNew, seasonHis});

    let seasonNewList = seasonNew.list || [];
    let total = Math.ceil(seasonNewList.length/2);
    const [page, setPage] = useState(0);

    let seasonHisList = seasonHis.list || [];
    let totalHis = Math.ceil(seasonHisList.length/2);
    const [pageHis, setPageHis] = useState(0);

    useEffect(()=>{
        dispatch(getData({
            name: 'seasonNew'
        }));
        dispatch(getData({
            name: 'seasonHis'
        }));
    }, []);

    useEffect(()=>{
        let seasonId = (seasonNewList[0] || {}).sid || '';
        if(!seasonId){
            return;
        }
        let address = cache.get('account');
        dispatch(getSignCounts({
            seasonId, address
        }))
    }, [seasonNewList]);

	return (
        <div className="chain-body chain-body-season">
            <div className="website-part-main">
                <div className="chain-content-title">
                    <h2>Season</h2>
                </div>

                <div className="chain-seasons-new">
                    {seasonNewList[page*2] && <NewSeasonItem seasonInfo={ seasonNewList[page*2] } signCounts={ signCounts } />}
                    {seasonNewList[page*2 + 1] && <NewSeasonItem seasonInfo={ seasonNewList[page*2 + 1] } signCounts={ signCounts } />}
                </div>
                
                {total > 1 && <div className="website-gallery-index chain-seasons-pages" style={{ position: 'relative' }}>
                    {[...Array(total)].map((x, index) =>
                        <span 
                            onClick={ ()=>{ setPage(index); } }
                            style={{ zoom: 0.6, margin: '0 5px' }}
                            className={ index === page ? 'current' : '' } 
                            key={ index }>
                            { index }
                        </span>
                    )}
                    <div className="website-gallery-prev"
                        style={{
                            top: '-120px',
                            left: '-40px'
                        }}
                        onClick={ ()=>{
                            let current = page - 1;
                            if(current < 0){
                                current = total - 1;
                            }
                            setPage(current);
                        } 
                    }>prev</div>
                    <div className="website-gallery-next"
                        style={{
                            top: '-120px',
                            right: '-40px'
                        }}
                        onClick={ ()=>{
                            let current = page + 1;
                            if(current >= total){
                                current = 0;
                            }
                            setPage(current);
                        } 
                    }>next</div>
                </div>}
                <div style={{ height: '40px' }}></div>    
            </div>      

            <div className="website-part-main">
                <div className="chain-content-title">
                    <h2>History</h2>
                </div>

                <div className="chain-season-list">
                    {seasonHisList[pageHis*2] && <Ranks seasonInfo={ seasonHisList[pageHis*2] } />}
                    {seasonHisList[pageHis*2 + 1] && <Ranks seasonInfo={ seasonHisList[pageHis*2 + 1] } />}
                </div>     
                
                
                {totalHis > 1 && <div className="website-gallery-index chain-seasons-pages" style={{ position: 'relative' }}>
                    {[...Array(totalHis)].map((x, index) =>
                        <span 
                            onClick={ ()=>{ setPageHis(index); } }
                            style={{ zoom: 0.6, margin: '0 5px' }}
                            className={ index === pageHis ? 'current' : '' } 
                            key={ index }>
                            { index }
                        </span>
                    )}
                    <div className="website-gallery-prev"
                        style={{
                            top: '-300px',
                            left: '-40px'
                        }}
                        onClick={ ()=>{
                            let current = pageHis - 1;
                            if(current < 0){
                                current = totalHis - 1;
                            }
                            setPageHis(current);
                        } 
                    }>prev</div>
                    <div className="website-gallery-next"
                        style={{
                            top: '-300px',
                            right: '-40px'
                        }}
                        onClick={ ()=>{
                            let current = pageHis + 1;
                            if(current >= totalHis){
                                current = 0;
                            }
                            setPageHis(current);
                        } 
                    }>next</div>
                </div>}
            </div>  

            <div style={{ height: '40px' }}></div>    
        </div>      
	)
}

function NewSeasonItem(props){
    let {seasonInfo, signCounts} = props;

    return (<div className="chain-season-mini">
        <div className="chain-season-mini-left">
        <h3 className="title">New Season Start</h3>
        <div className="chain-season-pool">
            <span className="chain-icon-reward"></span>
            <div>
                <span className="name">POOL</span> 
                <span className="total">
                    <NumericFormat value={ seasonInfo.reward_amount_1 } displayType="text" thousandSeparator={true} prefix="" />
                </span> 
            </div>
        </div>
        </div>
        <div className="chain-season-mini-right">
        <div className="chain-season-time">
            <h3 className="name">{ seasonInfo.sid }</h3>
            <span className="time">{ dateFormat(seasonInfo.start_ts/0.001, 'yyyy/MM/dd') } - { dateFormat(seasonInfo.end_ts/0.001, 'yyyy/MM/dd') }</span>
        </div>
        <div className="chain-season-btns">
            <span className="chain-btn chain-btn-blue how2play"
                onClick={ ()=>{
                    // alert('how to play');
                } }>
                HOW TO PLAY
            </span>
            <a target="_blank" href={ basicInfo.game } className="chain-btn play">
                {isSeasonRunning(seasonInfo) ? 'PLAY' : 'SIGN UP'}
            </a>
        </div>
        <div className="chain-season-signups">
            Applications: { signCounts.signed }/{ signCounts.total || 200 }
        </div>
        </div>
    </div>);
}
