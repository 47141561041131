import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { numberFormat, dateFormat, timeFormat, createRadarSvg } from '../utils/utils'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath
} from '../utils/ui-res'

import RankTable from './rank-table';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Ranks(props){
    const { seasonInfo } = props;
    let unionRewards = seasonInfo.union_reward_result || [];
    let unionRewardsTop3 = unionRewards.slice(0, 3);
    let gloryRewards = seasonInfo.glory_reward_result || [];
    let gloryRewardsTop3 = gloryRewards.slice(0, 3);

    const [unionPop, setUnionPop] = useState(false);
    const [gloryPop, setGloryPop] = useState(false);

    useEffect(() => {
    });

    return (<>
        <div className="chain-season-his-item chain-season-his-item-border">
            <RankHead seasonInfo={ seasonInfo} />

            <div className="chain-season-victory">
                VICTORY CAMP: <img src={ unionImagePath(seasonInfo.union_win_id || 1) } />
            </div>

            <div className="App-game-table App-game-table-rank">
                <div className="chain-season-rank-head">
                    Conqueror Rewards
                </div>
                <RankTable data={ unionRewardsTop3 } />
                {unionRewards.length > 3 && <div className="chain-season-rank-more" onClick={ ()=>{
                    setUnionPop(true);   
                }}>MORE INFO</div>}
            </div>

            <div className="App-game-table App-game-table-rank">
                <div className="chain-season-rank-head">
                    Medal Ranking Rewards
                </div>
                <RankTable data={ gloryRewardsTop3 } />
                {gloryRewards.length > 3 && <div className="chain-season-rank-more" onClick={ ()=>{
                    setGloryPop(true);   
                }}>MORE INFO</div>}
            </div>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.5)' },
                content: {
                    width: '600px',
                    marginLeft: '-300px',
                    height: '640px',
                    marginTop: '-320px'
                }
            }}
            className = { 'chain-data-modal' }
            isOpen={ unionPop }
            onRequestClose={ (e)=>setUnionPop(false) }
            contentLabel="Union Modal">
            <div className="chain-data-modal-border">
                <div className="chain-data-modal-close" onClick={ (e)=>setUnionPop(false) }>Close</div>
                <div className="chain-season-his-item" style={{ width: 'calc(100% - 40px)', padding: '20px' }}>
                    <RankHead seasonInfo={ seasonInfo} />

                    <div className="chain-season-victory" style={{
                        borderTop: '2px solid rgba(255, 240, 195, 0.2)',
                        paddingTop: '20px'
                    }}>
                        VICTORY CAMP: <img src={ unionImagePath(seasonInfo.union_win_id || 1) } />
                    </div>

                    <div className="App-game-table App-game-table-rank" onClick={ ()=>{
                        setUnionPop(true);
                        }}>
                        <div className="chain-season-rank-head">
                            Conqueror Rewards
                        </div>
                        <div style={{ height: '410px', overflow: 'auto' }}>
                            <RankTable data={ unionRewards } />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.5)' },
                content: {
                    width: '600px',
                    marginLeft: '-300px',
                    height: '640px',
                    marginTop: '-320px'
                }
            }}
            className = { 'chain-data-modal' }
            isOpen={ gloryPop }
            onRequestClose={ (e)=>setGloryPop(false) }
            contentLabel="Glory Modal">
            <div className="chain-data-modal-border">
                <div className="chain-data-modal-close" onClick={ (e)=>setGloryPop(false) }>Close</div>
                <div className="chain-season-his-item" style={{ width: 'calc(100% - 40px)', padding: '20px' }}>
                    <RankHead seasonInfo={ seasonInfo} />

                    <div className="chain-season-victory" style={{
                        borderTop: '2px solid rgba(255, 240, 195, 0.2)',
                        paddingTop: '20px'
                    }}>
                        VICTORY CAMP: <img src={ unionImagePath(seasonInfo.union_win_id || 1) } />
                    </div>

                    <div className="App-game-table App-game-table-rank" onClick={ ()=>{
                        setUnionPop(true);
                        }}>
                        <div className="chain-season-rank-head">
                            Medal Ranking Rewards
                        </div>
                        <div style={{ height: '410px', overflow: 'auto' }}>
                            <RankTable data={ gloryRewards } />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}

function RankHead(props){
    const { seasonInfo = {} } = props;
    return (<div className="chain-season-his-head">
        <div className="chain-season-time">
            <h3 className="name">{ seasonInfo.sid }</h3>
            <span className="time">{ dateFormat(seasonInfo.start_ts/0.001, 'yyyy/MM/dd') } - { dateFormat(seasonInfo.end_ts/0.001, 'yyyy/MM/dd') }</span>
        </div>
        <div className="chain-season-pool">
            <span className="chain-icon-reward"></span>
            <div>
                <span className="name">POOL</span> 
                <span className="total">
                    <NumericFormat value={ seasonInfo.reward_amount_1 } displayType="text" thousandSeparator={true} prefix="" />
                </span> 
            </div>
        </div>
    </div>);
}
