import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";
import { getData } from '../services/slice'

import { Rect } from '../components';
import { dateFormat } from '../utils/utils';
import { getMedal } from '../utils/ui-res';

import './medals.scss';

import { imageData } from '../utils/ui-res';
const images = imageData();

export default function Medals(props){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const [tabCurrent, setTabCurrent] = useState('item');

    const { items = {}, medals = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    let nfts = items.list || [];
    let medalList = medals.list || [];
    let types = {
        gold: 1,
        silver: 2,
        bronze: 3
    };
    console.log(medalList)

    let { account } = props;
    useEffect(()=>{
        dispatch(getData({
            name: 'items',
            params: {
                account
            }
        }));
        dispatch(getData({
            name: 'medals',
            params: {
                account
            }
        }));
    }, []);

	return (
		<div className="chain-content chain-medals">
            <div className="chain-tabs">
                <div 
                    onClick={ ()=>{
                        setTabCurrent('item');
                    }}
                    className={tabCurrent === 'item' ? "chain-tab-highlight": "chain-tab-normal"}>
                    &nbsp;ITEM&nbsp;
                </div>
                <div 
                    onClick={ ()=>{
                        setTabCurrent('medal');
                    }}
                    className={tabCurrent === 'medal' ? "chain-tab-highlight": "chain-tab-normal"}>
                    MEDAL
                </div>
            </div>
            <div className="website-part-main" style={{ display: tabCurrent === 'item' ? 'block' : 'none'}}>
                <div className="chain-items">
                    {
                        nfts.map((item, index)=>{
                            return <div className="chain-item" key={ index }>
                                <img 
                                    onError = { (e)=>{ e.target.src = images.nft } }
                                    src={ item.imageUrl } title={ item.name } key={ index }  />
                            </div>
                        })
                    }
                    {nfts.length === 0 && <>
                        <div className="chain-nodata">You haven't got any nft yet.</div>
                        <div className="chain-btn" style={{ width: '10rem', margin: '-20px auto 0' }}>View Marketplace</div>
                    </>}
                </div>
            </div>
            <div className="website-part-main" style={{ display: tabCurrent === 'medal' ? 'block' : 'none'}}>
                <div className="chain-medals">
                    {
                        medalList.map((item, index)=>{
                            return <div className="chain-medal-item" key={ index }>
                                <img src={ getMedal(types[item.type] || item.type) } key={ index } title={ item.type } />
                                <span className="season">{ item.seasonId.replace('season-','S') }</span>
                            </div>
                        })
                    }
                    {medalList.length === 0 && <>
                        <div className="chain-nodata">You haven't got any medal yet.</div>
                        <div className="chain-tips" style={{ margin: '-20px 0 0' }}>The top 5 players in the season will get medal.</div>
                    </>}
                </div>
            </div>
		</div>
	)
}