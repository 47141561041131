import NFTs from './nft';
import { Footer, Menu } from '../components';
import { Rect } from '../components';

import { basicInfo } from '../const';
import { imageData } from '../utils/ui-res';
const images = imageData();

export default function dashboard(){
    return (<div className="website-body">
        <div className="website-game website-part">
            <img src={ images.bg1 } className="bg1" style={{width:'100%',display:'block'}} />
            <div className="website-game-content">
                <img src={ images.logoSmall } />
                <h1>A Web3 Strategy Game Played in DAOs</h1>
                <div className="website-play">
                    <a href={ basicInfo.game }>PLAY</a>
                </div>
            </div>
        </div>

        <div className="website-intro website-part">
            <div className="website-part-main website-introduction">
                <h2 className="title1">INTRODUCTION</h2>
                <article>League of Thrones is played by seasons, there will be a predefined rewards for the winning legion and top performing individuals each season. Players will be randomly shuffled into 4 legions when they register a new season, they will play together in order to conquer the center piece of the map, starting from the edge. Players can use their existing NFTs or League of Thrones’native NFTs within the game, and opt to pay for extra resources in order to win the season. The winning legion memebers have the opportunity to edit their native NFTs at the end of the season.</article>
            </div>

            <div className="website-part-main website-specs-title">
                <div className="title_top"></div>
                <h2 className="title1">SPECS</h2>
                <p>Leagus of Thrones`s mission is to become a decentralized game with high composability.</p>
            </div>

            <div className="website-part-main website-spec-list">
                <div className="website-spec-item">
                    <h3 className="chain-tab-highlight">OPENNESS</h3>
                    <Rect content={ <article className="website-spec-article">
                        Not only the game’s code is open sourced, but also all the media resource will be on arweave/IPFS with links shared. We will go cc0 on those art works, anyone could reuse it in their own ways.On top of that, the game could be hosted by anybody.
                    </article> } />
                </div>
                <div className="website-spec-item">
                    <h3 className="chain-tab-highlight">DATA ON CHAIN</h3>
                    <Rect content={ <article className="website-spec-article">
                        Most current blockchain games only put assets on chain (FT/NFT), leaving the gaming data in centralized servers. League of Thrones create it's own Gaming Data Availability Chain with tendermint consensus, enabling players to validate the gaming data.
                    </article> } />
                </div>
                <div className="website-spec-item">
                    <h3 className="chain-tab-highlight">DAOs</h3>
                    <Rect content={ <article className="website-spec-article">
                        League of Thrones is played in DAOs mandatorily, and the rewards will be given to the winning DAO, in the future we will integrate existing DAO management protocols to allow players split their rewards in their own way. 
                    </article> } />
                </div>
            </div>
        </div>

        <div className="website-gametree website-part">
            <div className="website-part-main">
                <div className="website-specs-title" style={{ margin: '-1.4rem 0 3rem' }}>
                    <h2 className="title1">COMPOSABILITY</h2>
                </div>

                <img src={ images.compose } alt="" className="compose" />
                <img src={ images.gnr_tex_greg } alt="" className="greg" />
                <img src={ images.gnr_tex_barret } alt="" className="barret" />
            </div>
        </div>

        <NFTs />

        <div className="website-roadmap website-part">
            <div className="website-part-main website-roadmap-timeline">
                <h2 className="title1">ROADMAP</h2>
                <dl className="website-roadmap-step">
                    <dt>2022 Q3</dt>
                    <dd>Finish building the game core and it's own GDAC(gaming data availability chain) with tendermint consensus.All art works saved on both IPFS and arweave with cc0.</dd>
                </dl>
                <dl className="website-roadmap-step">
                    <dt>2022 Q4</dt>
                    <dd>Blockchain explorer of GDAC
                    Integrade Chainlink’s VRF as random number generator, or other native RNG that layer1 blockchain provide
                    Integrade with ENS/other DID protocols along with social/chatting protocols to provide in-game chatroom with DID shown.</dd>
                </dl>
                <dl className="website-roadmap-step">
                    <dt>2023 Q1</dt>
                    <dd>Run the game on other EVM compatible chains or layer2s
                    Develop‘publishing tool’ for Guilds</dd>
                </dl>
                <dl className="website-roadmap-step">
                    <dt>2023 Q2</dt>
                    <dd>DAO management tools integration
                    Native NFT mint</dd>
                </dl>
                <dl className="website-roadmap-step">
                    <dt>2023 Q3</dt>
                    <dd>Betting system, letting players bet on the result of ongoing seasons
                    Native Governance Token</dd>
                </dl>
                <dl className="website-roadmap-step">
                    <dt>2023 Q4</dt>
                    <dd>Mobile adaption
                    Either migrate the current GDAC to 3rd party data availability chains like ceramic or volidium, or let 3rd party run nodes on League of Thrones’s GDAC.</dd>
                </dl>
            </div>
        </div>
    </div>)
}

    
