import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { 
	getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionDetail
} from '../services/slice'

import { Footer, Menu } from '../components';
import { Rect } from '../components';
import { dateFormat, shortHash } from '../utils/utils';

export default function BlockDetail(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

	const data = useParams();
	let { height } = data;

    const { blockDetail, transactionDetail } = useSelector(function(state){
        return state.chain;
    });
    let txList = blockDetail.Txs || [];
    // console.log(blockDetail, transactionDetail);

    useEffect(()=>{
        dispatch(getBlockDetail({
        	height: height
        }));
    }, []);
    useEffect(()=>{
    	if(txList[0]){
	        dispatch(getTransactionDetail({
	        	hash: txList[0]
	        }));
	    }
    }, [blockDetail.Txs]);

	return (
		<div className="chain-body">
			<div className="website-part-main">
				<div className="chain-content">
					<div className="chain-content-title">
						<h2>Block {height} Transctions</h2>
					</div>

					<Rect content={ <table className="chain-table">
						<thead><tr>
							<td>Hash</td>
							<td>Parent Hash</td>
							<td className="center">Block ID</td>
							<td className="center">From</td>
							<td className="center">Block Height</td>
							<td className="center">Timestamp</td>
						</tr></thead>
						<tbody>
			                {txList.map((item, index) =>
			                    <tr key={ index }>
									<td title={ item } 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item);
									} }>{ shortHash(item, 7) }</td>
									<td title={ blockDetail.ParentHash } 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item);
									} }>{ shortHash(blockDetail.ParentHash, 7) }</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item);
									} }>{ blockDetail.Id }</td>
									<td className="center">
										<Link to={ "/from/" + (blockDetail.From || transactionDetail.From) + "/transactions" }>
											{ blockDetail.From || transactionDetail.From }
										</Link>
									</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item);
									} }>{ blockDetail.Height }</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item);
									} }>{ dateFormat(blockDetail.Timestamp*1000) }</td>
								</tr>
			                )}
						</tbody>
					</table> } />
				</div>
			</div>
		</div>
	)
}