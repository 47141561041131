import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getData } from '../services/slice'

import { Footer, Menu } from '../components';
import { Rect, Gird2Cols } from '../components';
import { dateFormat } from '../utils/utils';
import * as echarts from 'echarts';

const { NumericFormat } = require('react-number-format');

export default function Dashboard(){
    const dispatch = useDispatch(); 

    const dayLength = 10;

	const [dailyDataReady, setDailyDataReady] = useState(false);
    const { statTotals = {}, statDaily = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    var dailyData = getDailyData(statDaily);

    useEffect(()=>{
        dispatch(getData({
            name: 'statTotals',
            params: {
            	r: Math.random()
            }
        }));

	    const endTime = new Date().getTime()/1000 - 24*60*60*2;
	    const startTime = endTime - 24*60*60*dayLength;
        dispatch(getData({
            name: 'statDaily',
            params: {
            	startTime: Math.floor(startTime),
            	endTime: Math.floor(endTime),
            	r: Math.random()
            }
        }));
    }, []);

    useEffect(()=>{
    	setDailyDataReady(true);
    }, [statDaily]);

    function getDailyData(statDaily){
    	let dates = [];
    	let data = [];
    	let daysTransactions = 0;
    	let last = 0;
    	var minY = 100000;
    	for(var date in statDaily){
    		dates.push(dateFormat(date + ' 00:00:00', 'M-d'));
    		data.push(statDaily[date]);
    		daysTransactions += statDaily[date]/1;
    		last = statDaily[date]/1;
    		minY = Math.min(minY, statDaily[date]/1);
    	}
    	return { dates, data, daysTransactions, last, minY}
    }

	let w1 = '740px';
	let grid = {
		w: w1,
		h: '160px',
	};

	if(!dailyDataReady){
		return(<></>)
	}

	return (
		<div className="website-part-main">
			<div className="chain-content-title">
				<h2>
					Daily Transactions
					<span style={{ color: 'rgba(255, 161, 0, 1)', marginLeft: '10px', zoom: 0.9 }}><NumericFormat value={ dailyData.last } displayType="text" thousandSeparator="," /></span>
				</h2>
			</div>
			<Rect content={ <>
				<div className="website-daily-transaction" style={{ width: w1, height: grid.h }}>
					{dailyData.daysTransactions > 0 && <DailyTransactions days={ dailyData } grid={ grid } />}
				</div>

				<div className="website-daily-totals" style={{ width: 'calc(100% - 80px - ' + w1 + ')' }}>
					<div className="website-daily-total-item">
						<label>Total Blocks</label>
						<span><NumericFormat value={ statTotals.total_blocks || 0 } displayType="text" thousandSeparator="," /></span>
					</div>
					<div className="website-daily-total-item">
						<label>Total Transactions</label>
						<span><NumericFormat value={ statTotals.total_transactions || 0 } displayType="text" thousandSeparator="," /></span>
					</div>
					<div className="website-daily-total-item">
						<label>Wallet Addresses</label>
						<span><NumericFormat value={ statTotals.total_wallets || 0 } displayType="text" thousandSeparator="," /></span>
					</div>
				</div>
			</> } />
		</div>
	)
}

function DailyTransactions(props){
	let { w, h } = props.grid || {};
	let { dates, data, minY } = props.days || {};
	let min = Math.floor(minY/100)*100;

	let option = {
		grid: {
			left: '60px',
			right: '10px',
			top: '5px',
			bottom: '20px'
		},
		textStyle: {
			color: 'rgba(119, 95, 74, 1)'
		},
		xAxis: {
			type: 'category',
			data: dates,
			axisLine: {
				show: false
			},
			axisLabel: {
				margin: 8,
				padding: [0, 0, 0, 10],
				align: 'left'
			},
			axisTick: {
				show: false
			},
			splitLine: {
				show: true,
				lineStyle: {
					color: ['rgba(65, 47, 32, 1)'],
					width: 1,
					type: 'solid'
				}
			}
		},
		yAxis: {
			type: 'value',
			min: min,
			_max: 5000,
			splitLine: {
				show: true,
				lineStyle: {
					color: ['rgba(65, 47, 32, 1)'],
					width: 1,
					type: 'solid'
				}
			}
		},
		series: [
			{
				data: data,
				type: 'line',
				symbolSize: 10,
				itemStyle: {
					color: 'rgba(167, 29, 1, 1)'
				},
				lineStyle: {
					color: 'rgba(255, 162, 0, 1)',
					width: 2.5
				},
				smooth: true
			}
		]
	};

    useEffect(()=>{
		var chartDom = document.querySelector('.dailyTransactions');
		var myChart = echarts.init(chartDom);
			myChart.setOption(option);
    }, []);

	return(
		<div className="dailyTransactions" style={{
			width: w,
			height: h
		}}>
		</div>
	);
}