import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";
import copy from 'copy-to-clipboard';

import * as API from '../services/api';
import { getData, postData } from '../services/slice'
import { Rect } from '../components';
import { dateFormat, shortHash, isEmailType } from '../utils/utils';
import { getAccountInfo } from '../utils/ui-res';
import * as cache from '../utils/cache';

import RankTable from './rank';

import './invite.scss'

export default function InviteFriends(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const [tabCurrent, setTabCurrent] = useState('my');
    const [copied, setCopied] = useState(false);

    const account = cache.get('account') || '';
    if(!account){
    	navigate('/');
    }
    const appLink = 'https://app.leagueofthrones.com/?from=' + account;
	let accountInfo = getAccountInfo(account);

	const shareLinks = {
		facebook: 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(appLink),
		twitter: 'https://twitter.com/intent/tweet?text=&url=' + encodeURIComponent(appLink)
	};

	const myInviteList = [{},{},{}];
	const rewardList = [{},{},{},{},{},{}];

    useEffect(()=>{
    }, []);

	return (
		<div className="chain-body invite-body">
			<div className="website-part-main">
				<div className="invite-slogon">INVITE FRIENDS TO PLAY AND GET REWARDS</div>
	            <div className="chain-content-title">
	                <h2>My Invitation link</h2>
	            </div>
			</div>
			<div className="website-part-main invite-link">
				<h1>Share your link. All friends registered through this link can get reward</h1>
                <div className="chain-content-wallet">
                    <div className="address">{ appLink }</div>
                    {copied && (<span className="copied">Copied!</span>)}
                    <a className="chain-copy" onClick={ ()=>{
                        copy(appLink, {
                            onCopy: function(){
                                setCopied(true);
                                setTimeout(function(){
                                    setCopied(false);   
                                }, 2000);
                            }
                        });
                    }}>copy</a>
                </div>
                <a href={ shareLinks.facebook } target="_blank" className="icon-invite-facebook" style={{ marginRight:'8px' }}>facebook</a>
                <a href={ shareLinks.twitter } target="_blank" className="icon-invite-twitter">twitter</a>

                <div className="invite-link-warning">
                	Please register first.
                </div>
			</div>

            <div className="chain-tabs">
                <div 
                    onClick={ ()=>{
                        setTabCurrent('my');
                    }}
                    className={tabCurrent === 'my' ? "chain-tab-highlight": "chain-tab-normal"}>
                    &nbsp;My Invitation&nbsp;
                </div>
                <div 
                    onClick={ ()=>{
                        setTabCurrent('rank');
                    }}
                    className={tabCurrent === 'rank' ? "chain-tab-highlight": "chain-tab-normal"}>
                    Ranking
                </div>
            </div>
            {tabCurrent === 'my' && <div className="website-part-main">
            	<div className="App-game-table invite-rank-table">
            		<RankTable data={ myInviteList } />
                </div>
            </div>}
            {tabCurrent === 'rank' && <div className="website-part-main">
            	<div className="App-game-table invite-rank-table">
            		<RankTable data={ rewardList } />
                </div>
            </div>}

            <div className="website-part-main invite-steps">
            	<h1>How to get invitation rewards</h1>
            	<ol>
            		<li className="invite-step1">1: Share the invitation code to friends and let them fill it in when regitstering</li>
            		<li className="invite-step2">2: Share the invitation code to friends and let them fill it</li>
            		<li className="invite-step3">3: Share the invitation code to friends and let them fill it</li>
            	</ol>
            </div>
		</div>
	)
}