import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Link
} from "react-router-dom";
import { redirect, useNavigate, useParams } from "react-router-dom";
import { 
	getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionDetail,
    getData
} from '../services/slice'

import { Rect } from '../components';
import { dateFormat, shortHash, getAllTypes } from '../utils/utils';

export default function Transactions(){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    let txTypesHide = {
	    3 : "ExecuteStrategy",
	    4 : "HireGeneral",
	    5 : "EnhanceGeneral",
	    7 : "MarchToPos",
	    9 : "DisableGeneral",
	    17 : "CancelDefenseBlock",
	    18 : "SetUnionId",
	    19 : "SetUnionWin",
	    20 : "SetSeasonEnd",
	    21 : "StartSeason",
	    22 : "SetIconId",
	    24 : "AddTestResource",
	    33 : "InitUserStates",
	    34 : "InitGlobalStates",
	    36 : "RegularTask",
	    37 : "SetGuideStep",
	    38 : "FirstLogin",
	    40 : "OutChainUserActivity"
    };
    let txTypesAll = getAllTypes(txTypesHide);
    const [txType, setTxType] = useState();

    const { transactionsByType = [] } = useSelector(function(state){
        return state.chain.userData;
    });

    useEffect(()=>{
        setTxType(txTypesAll[0].txType);
    }, []);
    useEffect(()=>{
    	if(!txType){
    		return;
    	}
    	dispatch(getData({
            name: 'transactionsByType',
            params: {
            	type: txType
            }
    	}));
    }, [txType]);

	return (
		<div className="website-part-main">
			<div className="chain-content">
				<div className="chain-types">
					<label htmlFor="select-types">Type</label>
					<span className="chain-select">
						<select id="select-types" onChange={ (e)=>{
							setTxType(e.target.value);
						} }>
		                {txTypesAll.map((item, index) =>
							<option key={ index } value={ item.txType }>
								{ item.name }
							</option>
		                )}
		                </select>
		                <span className="chain-select-arr">></span>
	                </span>
	            </div>

				<Rect content={ <>
					<table className="chain-table">
						<thead><tr>
							<td>Hash</td>
							<td className="center">Transaction ID</td>
							<td className="center">From</td>
							<td className="center">Transaction Height</td>
							<td className="center">Timestamp</td>
						</tr></thead>
						<tbody>
			                {transactionsByType.map((item, index) =>
			                    <tr key={ index }>
									<td title={ item.Hash } 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item.Hash);
									} }>{ shortHash(item.Hash, 7) }</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item.Hash);
									} }>{ item.Id }</td>
									<td className="center">
										<Link to={ "/from/" + item.From + "/transactions" }>
											{ item.From }
										</Link>
									</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item.Hash);
									} }>{ item.Height }</td>
									<td className="center" 
			                    	onClick={ ()=>{
										navigate('/transaction/' + item.Hash);
									} }>{ dateFormat(item.Timestamp*1000) }</td>
								</tr>
			                )}
						</tbody>
					</table>
				</> } />
			</div>
		</div>
	)
}