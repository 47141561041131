const types = {
    1 : "UpgradeFacility",
    2 : "Recruit",
    3 : "ExcuteStrategy",
    4 : "HireGeneral",
    5 : "EnhanceGeneral",
    6 : "AttackEnemy",
    7 : "MarchToPos",
    8 : "AbleGeneral",
    9 : "DisableGeneral",
    10 : "UpgradeGeneral",
    11 : "UpgradeGeneralSkill",
    12 : "SetDefenseGeneral",
    13 : "ReceiveTroop",
    14 : "Battle",
    15 : "DefenseBlock",
    16 : "AttackBlock",
    17 : "CancelDefenseBlock",
    18 : "SetUnionId",
    19 : "SetUnionWin",
    20 : "SetSeasonEnd",
    21 : "StartSeason",
    22 : "SetIconId",
    23 : "Recharge",
    24 : "AddTestResource",
    25 : "RecoverMorale",
    26 : "BuyStrategyPoint",
    27 : "StrategyBuySilver",
    28 : "StrategyBuyTroop",
    29 : "StrategyBuyMorale",
    30 : "StrategyBuyProtect",
    31 : "StrategyBuyStore",
    32 : "MiningBlock",
    33 : "InitUserStates",
    34 : "InitGlobalStates",
    35 : "DonateSilver",
    36 : "RegularTask",
    37 : "SetGuideStep",
    38 : "FirstLogin",
    39 : "StrategyBuyProtect1",
    40 : "OutChainUserActivity",
    41: "HealTroops",
    42: "SpyEnamy",
    43: "BuyOffer",
    44: "CreateCod",
    45: "CancelCod",
    46: "JoinCod",
    47: "QuitCod",
    48: "AttackBlockCod",
    49: "CodCreatorDetail"
};

function getTypeName(type){
    return types[type] || type;
}

export default {
    all: types,
    getTypeName: getTypeName
}
