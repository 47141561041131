import { medias } from '../const';

import oasis from './logos/oasis.png'
import yuzu from './logos/yuzu.png'

export default function Footer(){
	return (
		<div className="website-foot website-part">
            <div className="website-part-main website-partners">
                <h2 className="title1">PARTNERS</h2>
                <ul className="website-partner-list">
                    <li><img src={ oasis } alt="" /><span>oasis</span></li>
                    <li><img src={ yuzu } alt="" /><span>yuzu</span></li>
                </ul>
            </div>
            <div className="website-part-main website-follow">
                <div className="website-socails">
                    <a href={ medias.twitter } className="twitter">Twitter</a>
                    <a href={ medias.discord } className="discord">Discord</a>
                    <a href={ medias.telegram } className="telegram">Telegram</a>
                </div>
            </div>
            <div className="website-part-main website-copy">
                League of Thrones copyright 2022. All right reserved.
            </div>
        </div>
	);
}