import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate } from "react-router-dom";
import copy from 'copy-to-clipboard';

import { getData, getNFTCounts, getBalanceData } from '../services/slice'
import * as Contract from '../services/contract';

import { Rect } from '../components';
import { dateFormat } from '../utils/utils';

import './wallets.scss';

import coins from './assets/coins.png';
import { imageData } from '../utils/ui-res';
const images = imageData();

export default function Wallets(props){
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const [copied, setCopied] = useState(false);

    const { accountInfo = {} } = useSelector(function(state){
        return state.chain.userData;
    });
    const { balanceData, NFTCounts } = useSelector(function(state){
        return state.chain;
    });

    let { Airose = {}, Apex = {} } = NFTCounts;

    let { account } = props;
    useEffect(()=>{
        dispatch(getData({
            name: 'accountInfo',
            params: {
                account
            }
        }));
        dispatch(getBalanceData({account}));
    }, []);

    useEffect(()=>{
        let { nftInfos = [] } = accountInfo;
        nftInfos.forEach(function(NFTInfo){
            dispatch(getNFTCounts({ account, NFTInfo}))
        });
    }, [accountInfo]);

    // console.log({account, accountInfo, balanceData, NFTCounts});

	return (
        <div className="chain-content">
            <div className="chain-content-title">
                <h2>Wallets</h2>
            </div>

            <Rect content={
                <div className="chain-wallets">
                    <div className="chain-wallet-item chain-wallet-rose">
                        <div className="chain-wallet-rose-total">
                            <div className="total">
                                <span className="number">{ balanceData.rose || 0 }</span>
                                <span className="name">Rose</span>
                                <span className="value"> ≈ ${ balanceData.usd || 0 } USD</span>
                            </div>
                        </div>
                        <div className="chain-wallet-rose-balance">
                            <div className="total">
                                <img src={ coins } />
                                <span className="number">{ Math.floor(accountInfo.gold || 0)}</span>
                                <span className="name">Golds</span>
                            </div>
                        </div>

                        <div className="chain-content-wallet">
                            <div className="address"><strong>Address:</strong> { account }</div>
                            {copied && (<span className="copied">Copied!</span>)}
                            <a className="chain-copy" onClick={ ()=>{
                                copy(account, {
                                    onCopy: function(){
                                        setCopied(true);
                                        setTimeout(function(){
                                            setCopied(false);   
                                        }, 2000);
                                    }
                                });
                            }}>copy</a>
                        </div>
                    </div>
                    <div className="chain-wallet-item chain-wallet-nft">
                        <img src={ images.nft } />
                        <div className="total">
                            <span className="number">0</span>
                            <span className="name">LOT</span>
                        </div>
                    </div>
                    <div className="chain-wallet-item chain-wallet-ape">
                        <img src={ images.ape } />
                        <div className="total">
                            <span className="number">{ Apex.count || 0 }</span>
                            <span className="name">APE</span>
                        </div>
                    </div>
                    <div className="chain-wallet-item chain-wallet-ape chain-wallet-airose">
                        <img src={ images.airose } />
                        <div className="total">
                            <span className="number">{ Airose.count || 0 }</span>
                            <span className="name">AIROSE</span>
                        </div>
                    </div>
                </div>
            } />
		</div>
	)
}