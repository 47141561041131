import svg  from 'svg';
const { localStorage, sessionStorage } = window;

const types = {
    1 : "UpgradeFacility",
    2 : "Recruit",
    3 : "ExecuteStrategy",
    4 : "HireGeneral",
    5 : "EnhanceGeneral",
    6 : "AttackEnemy",
    7 : "MarchToPos",
    8 : "AbleGeneral",
    9 : "DisableGeneral",
    10 : "UpgradeGeneral",
    11 : "UpgradeGeneralSkill",
    12 : "SetDefenseGeneral",
    13 : "ReceiveTroop",
    14 : "Battle",
    15 : "DefenseBlock",
    16 : "AttackBlock",
    17 : "CancelDefenseBlock",
    18 : "SetUnionId",
    19 : "SetUnionWin",
    20 : "SetSeasonEnd",
    21 : "StartSeason",
    22 : "SetIconId",
    23 : "Recharge",
    24 : "AddTestResource",
    25 : "RecoverMorale",
    26 : "BuyStrategyPoint",
    27 : "StrategyBuySilver",
    28 : "StrategyBuyTroop",
    29 : "StrategyBuyMorale",
    30 : "StrategyBuyProtect",
    31 : "StrategyBuyStore",
    32 : "MiningBlock",
    33 : "InitUserStates",
    34 : "InitGlobalStates",
    35 : "DonateSilver",
    36 : "RegularTask",
    37 : "SetGuideStep",
    38 : "FirstLogin",
    39 : "StrategyBuyProtect1",
    40 : "OutChainUserActivity",
    41: "HealTroops",
    42: "SpyEnemy"
};

function getTypeName(type){
    return types[type] || type;
}

function getAllTypes(filter){
    filter = filter || {};
    let txTypes = [];
    for(let type in types){
        if(!filter[type]){
            txTypes.push({
                txType: type, 
                name: types[type]
            });   
        }
    }
    return txTypes;
}

function timeFormat(time){
    //time == s; 1D11H56M23S;
    if(!time){
        return '';
    }
    if(time === 0){
        return 0;
    }
    time = Math.round(time);
    let d = Math.floor(time/3600/24);
    let h = Math.floor((time/3600 - d*24));
    let m = Math.floor((time/60 - d*24*60 - h*60));
    let s = Math.floor((time - d*3600*24 - h*3600 - m*60));
    let str = s + 'S ';
    if(m > 0){
        str = m + 'M ' + str;
    }
    if(h > 0){
        str = h + 'H ' + str;
    }
    if(d > 0){
        str = d + 'D ' + str;
    }
    return  str;
}

function numberFormat(n){
    //1000 == 1.00K, >1000000 == 1.00M
    if(!n || n < 0){
        return 0;
    }
    let d = n > 0 ? '' : '-';
    n = Math.abs(n);
    if(n >= 1000000000){
        return d + '9999M';
    }if(n >= 1000000){
        return d +  Math.ceil(n/10000)/100 + 'M';
    }else if(n >= 1000){
        return d + Math.ceil(n/10)/100 + 'K';
    }else{
        return d + Math.ceil(n);
    }
}

function dateFormat(date, fmt) {
    fmt = fmt || 'yyyy/MM/dd hh:mm:ss';
    var dateObj;
    if(date){
        dateObj = new Date(date);
    }else{
        return '';
    }

    var o = {
        "M+" : dateObj.getMonth()+1,                 
        "d+" : dateObj.getDate(),                    
        "h+" : dateObj.getHours(),                   
        "m+" : dateObj.getMinutes(),                 
        "s+" : dateObj.getSeconds(),                 
        "q+" : Math.floor((dateObj.getMonth()+3)/3), 
        "S"  : dateObj.getMilliseconds()             
    };
    if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (dateObj.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
}

function createRadarSvg(skillsData){
    //side = 600;
    //value = 200;
    let points = {
        "v1":[[110,125],[110,141],[110,157],[110,173],[110,189],[110,205],[110,221],[110,237]],
        "v2":[[110,125],[124,133],[138,141],[152,149],[165,157],[179,165],[193,173],[207,181]],
        "v3":[[110,125],[124,117],[138,109],[152,101],[165,93],[179,85],[193,77],[207,69]],
        "v4":[[110,125],[110,109],[110,93],[110,77],[110,61],[110,45],[110,29],[110,13]],
        "v5":[[110,125],[96,117],[82,109],[68,101],[55,93],[41,85],[27,77],[13,69]],
        "v6":[[110,125],[96,133],[82,141],[68,149],[55,157],[41,165],[27,173],[13,181]]
    };

    let result = [];
    skillsData.forEach(function(_skill, index){
        let d = Math.round(_skill.value*3/100);
        result.push(points['v' + (index + 1)][d]);
    });

    var text = '<svg width="220" height="250" viewPort="0 0 220 250" version="1.1" xmlns="http://www.w3.org/2000/svg">'
     + '<polygon points="' + result.join(' ') + '" style="stroke:green; fill:rgba(0, 234, 255, 1); stroke-width: 0;" />'
     + '</svg>';
    let elem = svg(text);
    return elem;
}

function subs(temp, data, regexp){
    if(!(Object.prototype.toString.call(data) === "[object Array]")) data = [data];
    var ret = [];
    for (var i = 0, j = data.length; i < j; i++) {
        ret.push(replaceAction(data[i]));
    }
    return ret.join("");
    function replaceAction(object){
        return temp.replace(regexp || (/\\?\{([^}]+)\}/g), function(match, name){
            if (match.charAt(0) == '\\') return match.slice(1);
            return (object[name] != undefined) ? object[name] : '';
        });
    }
}

function getStringLength(str) {  
    str = str || '';
    var len = 0;    
    for (var i=0; i<str.length; i++) {    
        if (str.charCodeAt(i)>127 || str.charCodeAt(i)==94) {    
             len += 2;    
         } else {    
             len ++;    
         }    
     }    
    return len;    
}    

function shortHash(str, number) {
    let arrL = str.substring(0, number);    
    let arrR = str.substring(str.length - number, str.length);
    return arrL + '....' + arrR;    
}    

function isEmailType(v){
    let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    return reg.test(v);
}


export {
    timeFormat,
    dateFormat,
    numberFormat,
    createRadarSvg,
    subs,
    getStringLength,
    shortHash,
    getTypeName,
    getAllTypes,
    isEmailType
}