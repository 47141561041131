import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

import rootReducer from './services/store'
import App from './App';

// import sentryInit from './utils/sentry'
// sentryInit();

const middleware = [ thunk ]
const store = createStore(
    rootReducer,
    applyMiddleware(...middleware)
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);