import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { redirect, useNavigate, useParams } from "react-router-dom";

import { getData } from '../services/api'

import copy from 'copy-to-clipboard';

import { 
	getRecentBlocks,
    getBlockDetail,
    getRecentTransactions,
    getTransactionDetail
} from '../services/slice'

import { Rect, Gird2Cols } from '../components';
import { dateFormat, shortHash } from '../utils/utils';
import { Footer, Menu } from '../components';
import { txTypes } from '../const';

export default function TransactionsDetail(props){
    const dispatch = useDispatch(); 
	const data = useParams();

	const [copied, setCopied] = useState(false);
	const [tabCurrent, setTabCurrent] = useState('overview');

	let { hash } = data;
    const { transactionDetail } = useSelector(function(state){
        return state.chain;
    });
    let events = transactionDetail.Events || [];

    // console.log(hash, events, transactionDetail);

	let datas = {};
	datas.basic = [
		{ name: 'Version', value: transactionDetail.Version },
		{ name: 'Status', value: transactionDetail.Status, type: 'boolean' },
		{ name: 'Timestamp', value: dateFormat(transactionDetail.Timestamp*1000) }
		// { name: 'Event Root Hash', value: ''},
	];
	datas.block = [
		{ name: 'Block', value: transactionDetail.Height },
		{ name: 'Transaction Index', value: transactionDetail.TransactionIndex },
		{ name: 'Hash', value: transactionDetail.Hash},
		{ name: 'Sequence Number', value: transactionDetail.Id}
		// { name: 'Expiration Timestap', value: '' },
		// { name: 'Gas Fee', value: '0.928782772[8.33opods Gas Unit]'},
		// { name: 'Gas Unit Price', value: '0.97737873 APT'},
		// { name: 'Max Gas Limit', value: '13,32355 Gas Units'},
		// { name: 'VM Status', value: 'Execyted successfully'}
	];
	datas.sign = [
		{ name: 'Season ID', value: transactionDetail.SeasonId},
		{ name: 'Type', value: txTypes.getTypeName(transactionDetail.Type) },
		{ name: 'From', value: transactionDetail.From },
		{ name: 'Input', value: transactionDetail.InputArgs, type: "object" },
		// { name: 'Event Root Hash', value: '90883543e04543c73204'},
	];

	let statesChangedList = transactionDetail.StatesChanged || [];

    useEffect(()=>{
        dispatch(getTransactionDetail({hash: hash}));
    }, []);

	return (
		<div className="chain-body">
			<div className="website-part-main">
				<div className="chain-content">
					<div className="chain-content-title">
						<h2>TRANSACTION</h2>
					</div>
					<div className="chain-content-wallet">
						<h2 className="title">{ hash }</h2>
						{copied && (<span className="copied">Copied!</span>)}
						<a className="chain-copy" onClick={ ()=>{
							copy(hash, {
								onCopy: function(){
							  		setCopied(true);
							  		setTimeout(function(){
							  			setCopied(false);	
							  		}, 2000);
								}
							});
						}}>copy</a>
						<span className="user">User Transation</span>
					</div>

					<div className="chain-tabs">
						<div 
							onClick={ ()=>{
								setTabCurrent('overview');
							}}
							className={tabCurrent === 'overview' ? "chain-tab-highlight": "chain-tab-normal"}>
							<span className="chain-icon chain-icon-overview"></span>
							OVERVIEW
						</div>
						<div 
							onClick={ ()=>{
								setTabCurrent('event');
							}}
							className={tabCurrent === 'event' ? "chain-tab-highlight": "chain-tab-normal"}>
							<span className="chain-icon chain-icon-event"></span>
							&nbsp;EVENTS&nbsp;
						</div>
					</div>

					{(tabCurrent === 'overview' && transactionDetail.Version) && (<>
						<Rect content={ <Gird2Cols type="overview" data={ datas.basic } /> } />
						<Rect content={ <Gird2Cols type="overview" data={ datas.block } /> } />
						<Rect content={ <Gird2Cols type="overview" data={ datas.sign } /> } />
						{statesChangedList.length > 0 && <StateChangeList transactionDetail={ transactionDetail } />}
					</>)}

					{tabCurrent === 'event' && (<>
						{events.length === 0 && <Rect content={ <div className="chain-nodata">No events data.</div> } />}
		                {events.map(
		                	(item, index) =>
			                    <Rect key={ index } content={ <Gird2Cols type="events" data={ [
									{ name: 'Index', value: index, type: 'swicth' },
									{ name: 'Type', value: item.Type },
									{ name: 'Data', value: item.Obj, type: 'object' }
								] } /> } />
		                )}
					</>)}
				</div>
			</div>
		</div>
	)
}

function StateChangeList(props){
    const dispatch = useDispatch(); 

	const [ready, setReady] = useState(false);
	const [list, setList] = useState([]);

	const { transactionDetail } = props;
    const { SeasonId, Id, Hash, Height } = transactionDetail;
	const statesChangedList = transactionDetail.StatesChanged || [];

    useEffect(()=>{
		var changeList = new Array(statesChangedList.length);
		var times = 0;
		statesChangedList.forEach(function(item, index){
			let value1 = JSON.parse(JSON.stringify(item));
			getData({
	            name: 'stateChange',
	            params: {
	            	seasonId: SeasonId,
	            	transId: Id,
	            	stateId: item.id,
	            	r: Math.random()
	            }
	        }, function(res){
	        	changeList[index] = {
					type: 'diff',
					name: 'States Changed ' + (index + 1),
	        		value1: value1,
	        		value2: res.state || {}
	        	};
	        	times += 1;
	        	if(times === statesChangedList.length){
	        		setReady(true);
	        		setList(changeList);
	        	}
	        })
		});
    }, []);

    if(!ready || list.length === 0){
    	return(<></>)
    }

    return (
    	<Rect content={ <Gird2Cols type="overview" data={ list } /> } />
    )
}