import React, { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { numberFormat, dateFormat, timeFormat, createRadarSvg } from '../utils/utils'
import { 
    generalImagePath, 
    generalBigImagePath,
    generalTypeImagePath,
    generalSkillImagePath,
    unionImagePath
} from '../utils/ui-res'

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function RankTable(props){
    const { data = [] } = props;

    return (<table>
        <thead><tr>
            <td className="rank center" style={{ width: '200px' }}>INVITE DATE</td>
            <td className="player">FRIEND</td>
            <td className="right reward">REWARD</td>
            <td className="right reward"></td>
            <td className="center reward">DATA</td>
        </tr></thead>
        <tbody>
        {data.map((item, index) => (
            <tr key={ 'rank' + index }>
                <td className="center"><span className="index">2023/2/13 11:22:23</span></td>
                <td><span style={{ display:'inline-block', lineHeight: 1, paddingTop:'7px', width:'8.2em',overflow:'hidden',textOverflow:'ellipsis' }} title={ item.username }>{ item.username }</span></td>
                <td className="right reward" style={{ padding: '5px 0 0' }}> 
                    <span className="chain-icon-reward"></span>
                    x { numberFormat(item.count) } 
                </td>
                <td className="right reward"></td>
                <td className="center reward">data</td>
            </tr>)
        )}
        </tbody>
    </table>)
}